/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { handleVerifyRecaptcha } from 'Util/Recaptcha';

import { CONFIRMATION_REQUIRED } from './MyAccountCreateAccount.config';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const ORDER_ID = 'ORDER_ID';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Bookland/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    async onSuccess(form, fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat
        } = transformToNameValuePair(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat
            },
            password,
            orderID: sessionStorage.getItem(ORDER_ID)
        };

        if (isLoading) {
            return;
        }

        const isRecaptchaValid = await handleVerifyRecaptcha(this.props.googleReCaptchaProps);

        if (!isRecaptchaValid) {
            return false;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace Bookland/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch */
                () => process.exit(1)
            );

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                    );
                    history.push('/default/customer/account/login');
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }
}

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer));
