/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    MyAccountOrderItemsTableRow as SourceMyAccountOrderItemsTableRow
} from 'SourceComponent/MyAccountOrderItemsTableRow/MyAccountOrderItemsTableRow.component';

import './MyAccountOrderItemsTableRow.override.style.scss';

/** @namespace Bookland/Component/MyAccountOrderItemsTableRow/Component */
export class MyAccountOrderItemsTableRow extends SourceMyAccountOrderItemsTableRow {}

export default MyAccountOrderItemsTableRow;
