/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import MyAccountTabList from 'Component/MyAccountTabList';
import NoMatch from 'Route/NoMatch';
import {
    MyAccount as SourceMyAccount,
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription
} from 'SourceRoute/MyAccount/MyAccount.component';
import { isSignedIn } from 'Util/Auth';

import './MyAccount.override.style';

export {
    MyAccountNewsletterSubscription,
    MyAccountMyWishlist,
    MyAccountMyOrders,
    MyAccountDownloadable,
    MyAccountAddressBook,
    MyAccountDashboard
};

/** @namespace Bookland/Route/MyAccount/Component */
export class MyAccount extends SourceMyAccount {
    static propTypes = {
        ...super.propTypes,
        guestOrder: PropTypes.object.isRequired
    };

    renderContent() {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            onSignOut,
            isEditingActive,
            match,
            changeTabName,
            tabName,
            setTabSubheading,
            isTabEnabled,
            guestOrder
        } = this.props;

        if (!isSignedIn() && !guestOrder?.id) {
            return this.renderLoginOverlay();
        }

        if (!isTabEnabled(activeTab)) {
            return <NoMatch />;
        }

        const TabContent = this.getTabContent();
        const { title } = tabMap[activeTab];

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div
                  block="MyAccount"
                  elem="TabContent"
                  mods={ { activeTab } }
                >
                    <h1 block="MyAccount" elem="Heading" mix={ { block: 'Typography_h1' } }>
                        { title || tabName }
                        { this.renderSubHeading() }
                    </h1>
                    <Suspense fallback={ <Loader /> }>
                        <TabContent
                          isEditingActive={ isEditingActive }
                          match={ match }
                          changeTabName={ changeTabName }
                          tabMap={ tabMap }
                          setTabSubheading={ setTabSubheading }
                        />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }
}

export default MyAccount;
