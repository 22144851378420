/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountCustomerFormContainer as SourceMyAccountCustomerFormContainer
} from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.container';

/** @namespace Bookland/Component/MyAccountCustomerForm/Container */
export class MyAccountCustomerFormContainer extends SourceMyAccountCustomerFormContainer {
    static propTypes = {
        ...super.propTypes,
        handleChangeEmailRadioButton: PropTypes.func.isRequired,
        handleChangePasswordRadioButton: PropTypes.func.isRequired
    };

    containerProps() {
        const {
            handleChangeEmailRadioButton,
            handleChangePasswordRadioButton
        } = this.props;

        return {
            ...super.containerProps(),
            handleChangeEmailRadioButton,
            handleChangePasswordRadioButton
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCustomerFormContainer);
