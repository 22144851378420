/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CmsBlockQuery as SourceCmsBlockQuery } from 'SourceQuery/CmsBlock.query';
import { Field } from 'Util/Query';

/** @namespace Bookland/Query/CmsBlock/Query */
export class CmsBlockQuery extends SourceCmsBlockQuery {
    getBlockEnabledQuery(identifier) {
        return new Field('isBlockEnable')
            .addArgument('identifier', 'String!', identifier);
    }
}

export default new CmsBlockQuery();
