/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    BILLING_STEP as SOURCE_BILLING_STEP,
    BILLING_URL as SOURCE_BILLING_URL,
    BILLING_URL_STEP as SOURCE_BILLING_URL_STEP,
    CHECKOUT_URL as SOURCE_CHECKOUT_URL,
    CHECKOUT_URL_REGEX as SOURCE_CHECKOUT_URL_REGEX,
    DETAILS_STEP as SOURCE_DETAILS_STEP,
    DETAILS_URL_STEP as SOURCE_DETAILS_URL_STEP,
    PAYMENT_TOTALS as SOURCE_PAYMENT_TOTALS,
    SHIPPING_STEP as SOURCE_SHIPPING_STEP,
    SHIPPING_URL as SOURCE_SHIPPING_URL,
    SHIPPING_URL_STEP as SOURCE_SHIPPING_URL_STEP,
    UPDATE_EMAIL_CHECK_FREQUENCY as SOURCE_UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

export const SHIPPING_STEP = SOURCE_SHIPPING_STEP;

export const BILLING_STEP = SOURCE_BILLING_STEP;

export const DETAILS_STEP = SOURCE_DETAILS_STEP;

export const BILLING_URL_STEP = SOURCE_BILLING_URL_STEP;

export const SHIPPING_URL_STEP = SOURCE_SHIPPING_URL_STEP;

export const DETAILS_URL_STEP = SOURCE_DETAILS_URL_STEP;

export const CHECKOUT_URL = SOURCE_CHECKOUT_URL;

export const BILLING_URL = SOURCE_BILLING_URL;

export const SHIPPING_URL = SOURCE_SHIPPING_URL;

export const CHECKOUT_URL_REGEX = SOURCE_CHECKOUT_URL_REGEX;

export const PAYMENT_TOTALS = SOURCE_PAYMENT_TOTALS;

export const UPDATE_EMAIL_CHECK_FREQUENCY = SOURCE_UPDATE_EMAIL_CHECK_FREQUENCY;

export const PAYU_CONFIRMATION = 'onepage';
export const REJECTED_STEP = 'rejected';
export const REJECTED_URL_STEP = '/rejected';
export const REJECTED_URL = '/checkout/rejected';
export const ALL_CONSENTS = 'all-consents';
