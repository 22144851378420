/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'SourceUtil/Query';
import history from 'Util/History';

const PAYU_TYPE = 'payu_gateway';

/**
 * Order Query
 * @class OrderQuery
 * @namespace Bookland/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    getGuestOrder(orderID, customer_email) {
        return new Field('guestOrders')
            .addArgument('filter', 'CustomerOrdersFilterInput', { number: { eq: orderID } })
            .addArgument('customer_email', 'String!', customer_email)
            .addFieldList([this._getOrderItemsField(true)]);
    }

    _getSingleOrderFields() {
        return [
            ...super._getSingleOrderFields(),
            'invoice_url'
        ];
    }

    _getOrdersField(options) {
        const { orderId, page = 1 } = options || {};
        const { state } = history.location;
        const ordersField = new Field('orders');

        if (state === 1) {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { number: { eq: orderId } })
                .addFieldList(this._getOrdersFields(true));
        }

        if (orderId) {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
                .addFieldList(this._getOrdersFields(true));
        }

        return ordersField
            .addArgument('currentPage', 'Int', page)
            .addFieldList(this._getOrdersFields());
    }

    _getOrderItemsFields(isSingleOrder) {
        const basicFields = [
            'id',
            'increment_id',
            'order_date',
            'status',
            'can_reorder',
            'rss_link',
            this._getPaymentAdditionalInformationField(),
            this._getOrderItemTotalField()
        ];

        if (isSingleOrder) {
            return [...basicFields, ...this._getSingleOrderFields()];
        }

        return basicFields;
    }

    _getPaymentAdditionalInformationField() {
        return new Field('payment_additional_information')
            .addFieldList(this._getPaymentAdditionalInformationFields());
    }

    _getPaymentAdditionalInformationFields() {
        return [
            'value',
            'key'
        ];
    }

    getNewPayUPaymentUrl(incrementId, paymentMethod) {
        if (paymentMethod === PAYU_TYPE) {
            return new Field('generateNewPayUPaymentUrlAndSendMail')
                .addArgument('increment_id', 'String!', incrementId);
        }

        return new Field('generateBlueMediaPaymentUrl')
            .addArgument('order_id', 'String!', incrementId);
    }
}

export default new OrderQuery();
