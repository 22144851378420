/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';

import './Accordion.style';

/** @namespace Bookland/Component/Accordion/Component */
export class Accordion extends PureComponent {
    static propTypes = {
        accordionItems: PropTypes.node.isRequired,
        getAccordionItemHeading: PropTypes.func.isRequired,
        getAccordionItemContent: PropTypes.func.isRequired,
        additionalClassName: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        additionalClassName: '',
        id: '',
        title: ''
    };

    render() {
        const {
            accordionItems, additionalClassName, getAccordionItemHeading, getAccordionItemContent, id, title
        } = this.props;

        if (!accordionItems || accordionItems?.length === 0) {
            return null;
        }

        return (
            <div block="Accordion" mix={ { block: additionalClassName } } id={ id }>
                <div block="Accordion" elem="Title">{ title }</div>
                { accordionItems.map((accordionItem) => (
                    <ExpandableContent
                      heading={ getAccordionItemHeading(accordionItem) }
                      mix={ { block: 'Accordion', elem: 'Box' } }
                      activeOnMobileAndDesktop
                      arrowIconBeforeHeading
                    >
                        { getAccordionItemContent(accordionItem) }
                    </ExpandableContent>
                )) }
            </div>
        );
    }
}

export default Accordion;
