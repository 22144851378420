/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

/** @namespace Bookland/Component/Notification/Component */
export class Notification extends SourceNotification {
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>{ __('Close') }</button>
                <p block="Notification" elem="Text">
                    { msgText }
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
