/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CATEGORY,
    PDP,
    SEARCH
} from 'Component/Header/Header.config';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { isScrollDisabled, toggleScroll } from 'Util/Browser';

import MobileSearchField from './MobileSearchField.component';

/** @namespace Bookland/Component/MobileSearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    isMobile: state.ConfigReducer.device.isMobile,
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState
});

/** @namespace Bookland/Component/MobileSearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
});
/** @namespace Bookland/Component/MobileSearchField/Container */
export class MobileSearchFieldContainer extends PureComponent {
    static propTypes = {
        activeOverlay: PropTypes.string.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isMobile: PropTypes.func.isRequired,
        navigationState: PropTypes.object.isRequired,
        setNavigationState: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired
    };

    containerFunctions = {
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this)
    };

    __construct() {
        this.state = {
            searchCriteria: ''
        };
    }

    containerProps() {
        const { activeOverlay } = this.props;
        const { searchCriteria } = this.state;

        return { activeOverlay, searchCriteria };
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        goToPreviousNavigationState();

        if (isScrollDisabled()) {
            toggleScroll(true);
        }

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onSearchOutsideClick() {
        this.hideSearchOverlay();
    }

    onClearSearchButtonClick() {
        this.setState({ searchCriteria: '' });
    }

    onSearchBarFocus() {
        const { showOverlay } = this.props;
        showOverlay(SEARCH);

        if (!isScrollDisabled()) {
            toggleScroll(false);
        }
    }

    render() {
        const { isMobile, navigationState: { name = '' } } = this.props;

        if (name !== PDP && name !== CATEGORY) {
            return null;
        }

        if (!isMobile) {
            return null;
        }

        return (
            <MobileSearchField
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchFieldContainer);
