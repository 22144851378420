/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Bookland/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getCategories() {
        return new Field('categories_matched').addFieldList(['name', 'url']);
    }

    _getProductFields() {
        const {
            categories, requireInfo, isSingleProduct, notRequireInfo, isSearch
        } = this.options;

        if (isSearch) {
            const items = [
                this._getSearchItemsField()
            ];

            if (categories) {
                return [...items, this._getCategories()];
            }

            return items;
        }

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            const items = [
                this._getItemsField()
            ];

            if (categories) {
                return [...items, this._getCategories()];
            }

            return items;
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getSearchItemsField() {
        const items = new Field('items')
            .addFieldList(this._getSearchInterfaceFields());

        return items;
    }

    _getSearchInterfaceFields() {
        const fields = [
            'sku',
            'name',
            'url',
            'id',
            this._getProductThumbnailField()
        ];

        return fields;
    }

    _getFbtProducts() {
        return new Field('fbt_products')
            .addFieldList(['sku', new Field('stock_item').addField('in_stock')]);
    }

    _getCategoryFields() {
        return [
            'id',
            'name',
            'url',
            'breadcrumbs_priority',
            this._getBreadcrumbsField()
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'availability',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'warehouse_salable_qty',
            'only_in_bookstore',
            this._getGroupedPrices(),
            this._getStockItemField(),
            this._getPriceRangeField()
        ];

        if (!isPlp) {
            fields.push([
                this._getAvailabilityInSources(),
                this._getFbtProducts()
            ]);
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductSmallField(),
                this._getTierPricesField()
            );

            if (!isPlp) {
                fields.push(
                    this._getShortDescriptionField(),
                    this._getProductImageField(),
                    this._getProductThumbnailField(),
                    'special_from_date',
                    'special_to_date',
                );
            }
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );

                if (!isPlp) {
                    fields.push(
                        this._getGroupedProductItems()
                    );
                }
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'url',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );

                if (!isPlp) {
                    fields.push(
                        this._getCategoriesField(),
                    );
                }
            }
        }

        return fields;
    }

    _getGroupedPrices() {
        return new Field('grouped_prices')
            .addFieldList([
                'omnibus_price',
                'final_price',
                'standard_price'
            ]);
    }

    _getGroupedProductItemStockFields() {
        return [
            new Field('product')
                .addFieldList([
                    'only_in_bookstore',
                    'stock_status',
                    this._getStockItemField()
                ])
        ];
    }

    _getGroupedProductStockFields() {
        return new Fragment('GroupedProduct').addField(
            new Field('items')
                .addFieldList(this._getGroupedProductItemStockFields())
        );
    }

    _getAvailabilityInSources() {
        return new Field('availability_in_sources')
            .addFieldList([
                'qty',
                'is_pickup_location',
                'source_code',
                'salable_qty'
            ]);
    }

    /* eslint-disable no-unused-vars */
    _getAttributeOptionsFields(isVariant) {
        return [
            this._getAttributeOptionsField()
        ];
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'availability',
            'name',
            'type_id',
            'stock_status',
            'url',
            'only_in_bookstore',
            'warehouse_salable_qty',
            this._getAvailabilityInSources(),
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getPriceRangeField()
        ];
    }
}

export default new ProductListQuery();
