/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceStore/Cart/Cart.action';

export const SELECT_BOOKSTORE = 'SELECT_BOOKSTORE';

/** @namespace Bookland/Store/Cart/Action/selectBookstore */
export const selectBookstore = (bookstore) => ({
    type: SELECT_BOOKSTORE,
    bookstore
});
