/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ExpandableContent from 'Component/ExpandableContent';
import { MyAccountTabList as SourceMyAccountTabList } from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';
import { MY_DOWNLOADABLE, MY_WISHLIST } from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';

import './MyAccountTabList.override.style.scss';

/** @namespace Bookland/Component/MyAccountTabList/Component */
export class MyAccountTabList extends SourceMyAccountTabList {
    render() {
        const {
            activeTab,
            isContentExpanded,
            tabMap,
            toggleExpandableContent
        } = this.props;
        const { tabName } = tabMap[activeTab];

        if (!isSignedIn()) {
            return <div />;
        }

        const tabs = [
            ...Object.entries(tabMap).map(this.renderTabListItem.bind(this)),
            this.renderLogoutTab()
        ];

        const filtredTabs = tabs.filter((item) => item.key !== MY_DOWNLOADABLE && item.key !== MY_WISHLIST);

        return (
            <ExpandableContent
              heading={ tabName }
              isContentExpanded={ isContentExpanded }
              mix={ { block: 'MyAccountTabList' } }
              onClick={ toggleExpandableContent }
              mods={ { isWithoutBorder: true } }
            >
                <ul>
                    { filtredTabs }
                </ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabList;
