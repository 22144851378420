/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const PICK_UP_FROM_BOOKSTORE_POPUP_ID = 'PICK_UP_FROM_BOOKSTORE_POPUP_ID';
export const PICK_UP_CARD = 'card';
export const PICK_UP_CHECKOUT = 'checkout';

export const AVAILABLE_NOW_STATUS = 0;
export const NEED_SEND_STATUS = 1;
export const NOT_AVAILABLE_STATUS = 2;
