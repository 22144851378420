/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ORDER_ITEMS } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { MyAccountOrderItemsTable as SourceMyAccountOrderItemsTable } from 'SourceComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.component';

import './MyAccountOrderItemsTable.override.style.scss';

/** @namespace Bookland/Component/MyAccountOrderItemsTable/Component */
export class MyAccountOrderItemsTable extends SourceMyAccountOrderItemsTable {
    renderOrderTitleAndActions() {
        const { activeTab, items: { number }, isMobile } = this.props;

        if (isMobile && activeTab === ORDER_ITEMS) {
            return (
                <div block="MyAccountOrderItemsTable" elem="OrderTitle">
                    { __('Items Ordered') }
                </div>
            );
        }

        const displayTranslationHeading = activeTab === 'Items' ? `${__(activeTab)} # ${number}` : `${activeTab} # ${number}`;

        return (
            <div block="MyAccountOrderItemsTable" elem="OrderActions">
                <div block="MyAccountOrderItemsTable" elem="OrderTitle">
                    { displayTranslationHeading }
                </div>
                { this.renderPrintAction() }
            </div>
        );
    }
}

export default MyAccountOrderItemsTable;
