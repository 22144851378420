/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Bookland/Component/ScrollToId/Component */
export class ScrollToId extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        id: PropTypes.string.isRequired
    };

    scrollToId = () => {
        const { id } = this.props;

        if (id) {
            const element = document.querySelector(`#${id}`);

            return element ? element.scrollIntoView({ behavior: 'smooth' }) : null;
        }

        return null;
    };

    render() {
        const { children } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a block="ScrollToId" onClick={ this.scrollToId }>
                { children }
            </a>
        );
    }
}

export default ScrollToId;
