/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { ImageContainer as SourceContainer } from 'SourceComponent/Image/Image.container';

/** @namespace Bookland/Component/Image/Container */
export class ImageContainer extends SourceContainer {
     static propTypes = {
         ...super.propTypes,
         preventLazy: PropTypes.bool
     };

     static defaultProps = {
         ...super.defaultProps,
         preventLazy: false
     };

     containerProps() {
         const {
             isPlaceholder,
             src,
             title,
             alt,
             ratio,
             mix,
             imageRef,
             isPlain,
             showIsLoading,
             onImageLoad,
             preventLazy
         } = this.props;

         return {
             style: this._getStyle(),
             wrapperSize: this._getWrapperSize(),
             isPlaceholder,
             src,
             title,
             alt,
             className: this._getCorrectClassNames(),
             ratio,
             mix,
             imageRef,
             isPlain,
             showIsLoading,
             isCached: this._isCached(),
             onImageLoad,
             preventLazy
         };
     }
}

export default ImageContainer;
