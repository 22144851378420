/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Loader from 'Component/Loader';
import { OrderType } from 'Type/Order.type';
import { MyAccountOrderInformation as SourceMyAccountOrderInformation } from 'SourceComponent/MyAccountOrderInformation/MyAccountOrderInformation.component'
import './MyAccountOrderInformation.override.style.scss';

import { PAYU_METHOD_PAYMENT, HOUR_IN_ONE_DAY, BANK, PAYU_TYPE, BM_TYPE } from './MyAccountInformation.config';

/** @namespace Component/MyAccountOrderInformation/Component */
export class MyAccountOrderInformation extends SourceMyAccountOrderInformation {
    static propTypes = {
        order: OrderType.isRequired
    };

    componentDidMount() {
        const { handleRetryPaymentTime } = this.props;
        handleRetryPaymentTime();
    }

    renderAccountNumber() {
        const { order: { payment_additional_information } } = this.props;

        const bankInformation = payment_additional_information.filter(item => item.key === BANK);

        if(bankInformation?.length <= 0) {
            return;
        }

        return (
            <>
                <div
                    block="MyAccountOrderInformation"
                    elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Account number') }</span>
                    </strong>
                </div>
                <div
                    block="MyAccountOrderInformation"
                    elem="Status">
                    <span>{ bankInformation[0]?.value || '' }</span>
                </div>
            </>
        )
    }

    renderRetryPaymentButton() {
        const { handleRetryPayment,
            retryPaymentTime,
            order: { payment_methods = [], payment_additional_information = [] }
         } = this.props;

        const bmPaymentStatus = payment_additional_information?.find(({key}) => key === 'bluepayment_state')?.value

        if((payment_methods[0]?.type !== PAYU_TYPE && payment_methods[0]?.type !== BM_TYPE) || (payment_methods[0]?.type === BM_TYPE && bmPaymentStatus === 'SUCCESS') || retryPaymentTime >= HOUR_IN_ONE_DAY) {
            return;
        }

        return (
            <button
                block="MyAccountOrderInformation"
                elem="RetryPaymentButton"
                mix={ { block: 'Button' } }
                onClick={ handleRetryPayment }
            >
                { __('Retry payment') }
            </button>
        )
    }

    renderPaymentMethods() {
        const { order: { payment_methods = [], status } } = this.props;

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Column"
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Payment Method') }</span>
                    </strong>
                </div>
                { payment_methods.map(this.renderPaymentMethod.bind(this)) }
                <div
                  block="MyAccountOrderInformation"
                  elem="ColumnTitle"
                >
                    <strong>
                        <span>{ __('Status') }</span>
                    </strong>
                </div>
                <div
                    block="MyAccountOrderInformation"
                    elem="Status"
                >
                        <span>{ status }</span>
                </div>
                { this.renderAccountNumber() }
                { this.renderRetryPaymentButton() }
            </div>
        )
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div
              block="MyAccountOrderInformation"
              elem="Wrapper"
            >
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }
}

export default MyAccountOrderInformation;
