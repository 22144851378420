/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { history as utilHistory } from 'Util/History';

import {
    MENU,
    SEARCH
} from './Header.config';

export {
    mapDispatchToProps,
    DEFAULT_HEADER_STATE
};
export const CHECKOUT_URL = '/checkout';

/** @namespace Bookland/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    prices: state.CartReducer.cartTotals.prices,
    isHideLayout: state.HideLayoutReducer.hideLayout,
    isPromoCounterVisible: state.ConfigReducer.isPromoCounterVisible
});

/** @namespace Bookland/Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    static propTypes = {
        ...SourceHeaderContainer.propTypes,
        prices: PropTypes.object.isRequired,
        isHideLayout: PropTypes.bool.isRequired,
        isPromoCounterVisible: PropTypes.bool.isRequired
    };

    state = {
        isOpen: true
    };

    containerFunctions = {
        onBackButtonClick: this.onBackButtonClick.bind(this),
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onEditButtonClick: this.onEditButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onOkButtonClick: this.onOkButtonClick.bind(this),
        onCancelButtonClick: this.onCancelButtonClick.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onMyAccountOutsideClick: this.onMyAccountOutsideClick.bind(this),
        onMinicartOutsideClick: this.onMinicartOutsideClick.bind(this),
        onSignIn: this.onSignIn.bind(this),
        shareWishlist: this.shareWishlist.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay,
        onTopBarButtonClick: this.onTopBarButtonClick.bind(this)
    };

    containerProps() {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            compareTotals,
            Loading,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading,
            productsInWishlist,
            prices,
            isHideLayout,
            isPromoCounterVisible
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            shouldRenderCartOverlay,
            isOpen
        } = this.state;

        const {
            location: {
                pathname
            }
        } = utilHistory;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            compareTotals,
            Loading,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            productsInWishlist,
            shouldRenderCartOverlay,
            firstname: this.getUserName(),
            isOpen,
            prices,
            isHideLayout,
            isPromoCounterVisible
        };
    }

    onBackButtonClick(e) {
        const { navigationState: { onBackClick } } = this.props;

        this.setState({ searchCriteria: '' });

        if (onBackClick) {
            onBackClick(e);
        } else {
            history.back();
        }
    }

    onTopBarButtonClick() {
        this.setState(
            (prevstate) => {
                if (prevstate.isOpen) {
                    const store = document.querySelector(':root');
                    store.style.setProperty('--promo-bar-mobile-height', '0px');
                    store.style.setProperty('--promo-bar-desktop-height', '0px');
                }

                return { isOpen: !prevstate.isOpen };
            }
        );
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }

    hideSearchOnStateChange(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName === SEARCH && prevName !== name) {
            this.hideSearchOverlay();
        }
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            navigationState: { name }
        } = this.props;

        if (name === SEARCH) {
            return;
        }

        showOverlay(SEARCH);

        setNavigationState({
            name: SEARCH,
            onBackClick: () => {
                showOverlay(MENU);
                goToPreviousNavigationState();
            }
        });
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
