/* eslint-disable no-magic-numbers */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace Bookland/Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {
    renderSublabel(subLabel) {
        const { isProductCountVisible } = this.props;

        if (!subLabel || !isProductCountVisible) {
            return null;
        }

        return (
            <strong
              block="ProductAttributeValue"
              elem="SubLabel"
            >
              { `${subLabel}` }
            </strong>
        );
    }

    renderTextAttribute() {
        const { attribute: { attribute_value, attribute_code } } = this.props;

        if (attribute_code === 'publication_year') {
            return this.renderStringValue(`${parseInt(attribute_value?.trim(), 10)}`);
        }

        return this.renderStringValue(attribute_value);
    }
}

export default ProductAttributeValue;
