/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';

/** @namespace Bookland/Component/MenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Bookland/Component/MenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Bookland/Component/MenuItem/Container */
export class MenuItemContainer extends SourceMenuItemContainer {
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
