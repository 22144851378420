/* eslint-disable react/no-did-update-set-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import {
    IMAGE_LOADING
} from 'SourceComponent/Image/Image.config';

/**
  * Image component
  * Images are loaded only when they appear in a viewport
  * @class Image
  * @namespace Bookland/Component/Image/Component */
export class Image extends SourceImage {
     static propTypes = {
         ...super.propTypes,
         preventLazy: PropTypes.bool
     };

     static defaultProps = {
         ...super.defaultProps,
         preventLazy: false
     };

     renderStyledImage() {
         const {
             alt,
             src,
             style,
             title,
             preventLazy
         } = this.props;
         const { imageStatus } = this.state;
         const loadingType = preventLazy ? { fetchpriority: 'high' } : { loading: 'lazy' };

         return (
             <img
               block="Image"
               elem="Image"
               src={ src || '' }
               alt={ alt }
               mods={ { isLoading: imageStatus === IMAGE_LOADING } }
               style={ style }
               title={ title }
               onLoad={ this.onLoad }
               onError={ this.onError }
               { ...loadingType }
             />
         );
     }

     renderPlainImage() {
         const {
             alt,
             src,
             style,
             title,
             className,
             preventLazy
         } = this.props;
         const loadingType = preventLazy ? { fetchpriority: 'high' } : { loading: 'lazy' };

         return (
             <img
               block={ className }
               src={ src || '' }
               alt={ alt }
               style={ style }
               title={ title }
               onLoad={ this.onLoad }
               onError={ this.onError }
               { ...loadingType }
             />
         );
     }
}

export default Image;
