/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    UPDATE_CHECKOUT_SHIPPING_PRICE,
    UPDATE_DELIVERY_POINTS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_REVIEW_REMINDER_ACCEPTED,
    UPDATE_SELECTED_DELIVERY_POINTS,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace Bookland/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    shippingPrice: null,
    email: '',
    isEmailAvailable: true,
    deliveryPoints: [],
    selectedLocker: null,
    reviewReminderAccepted: false
});

/** @namespace Bookland/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };
    case UPDATE_CHECKOUT_SHIPPING_PRICE:
        const { shippingPrice } = action;

        return {
            ...state,
            shippingPrice
        };

    case UPDATE_DELIVERY_POINTS:
        const { deliveryPoints } = action;

        return {
            ...state,
            deliveryPoints
        };

    case UPDATE_SELECTED_DELIVERY_POINTS:
        const { selectedLocker } = action;

        return {
            ...state,
            selectedLocker
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_REVIEW_REMINDER_ACCEPTED:
        const { reviewReminderAccepted } = action;

        return {
            ...state,
            reviewReminderAccepted
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
