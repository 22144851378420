/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ClickOutside from 'Component/ClickOutside';
import SearchOverlay from 'Component/SearchOverlay';
import {
    SearchField as SourceSearchField
} from 'SourceComponent/SearchField/SearchField.component';

import './SearchField.override.style';

/** @namespace Bookland/Component/SearchField/Component */
export class SearchField extends SourceSearchField {
    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device
        } = this.props;

        return (
        <div
          block="SearchField"
          elem="SearchInnerWrapper"
        >
            <input
              id="search-field"
              ref={ this.searchBarRef }
              block="SearchField"
              elem="Input"
              onFocus={ onSearchBarFocus }
              onChange={ this.handleChange }
              onKeyDown={ this.onSearchEnterPress }
              value={ searchCriteria }
              mods={ { isActive } }
              placeholder={ __('Search products') }
              autoComplete="off"
              aria-label={ __('Search') }
              maxLength={ 255 }
            />
            { this.renderSearchIcon() }
            <SearchOverlay
              isHideOverlay={ !device.isMobile }
              clearSearch={ this.clearSearch }
              searchCriteria={ searchCriteria }
            />
        </div>
        );
    }

    render() {
        const {
            isVisible,
            isActive
        } = this.props;

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ this.closeSearch }>
                        <div block="SearchField" elem="Wrapper">
                            { this.renderSearch() }
                        </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchField;
