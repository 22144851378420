/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlockContainer as SourceCmsBlockContainer } from 'SourceComponent/CmsBlock/CmsBlock.container';
import { fetchQuery } from 'Util/Request';

import { CHECK_IS_ENABLED_BLOCK_IDENTIFIERS } from './CmsBlock.config';

/** @namespace Bookland/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    checkTime(html) {
        const { identifier } = this.props;

        if (CHECK_IS_ENABLED_BLOCK_IDENTIFIERS.includes(identifier)) {
            const fragment = document.createRange().createContextualFragment(html);
            const dateStringFromParam = fragment.querySelector('widget')?.getAttribute('params_json');

            return new Date(dateStringFromParam) > new Date();
        }

        return true;
    }

    async _getCmsBlock() {
        const { identifier } = this.props;

        if (CHECK_IS_ENABLED_BLOCK_IDENTIFIERS.includes(identifier)) {
            const { isBlockEnable } = await fetchQuery(CmsBlockQuery.getBlockEnabledQuery(identifier));

            if (!isBlockEnable) {
                return null;
            }
        }

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                if (this.checkTime(items[0]?.content)) {
                    this.setState({ cmsBlock: items[0] });
                }
            }
        );
    }
}

export default CmsBlockContainer;
