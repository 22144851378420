/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountInformationContainer as SourceMyAccountInformationContainer
} from 'SourceComponent/MyAccountInformation/MyAccountInformation.container';

/** @namespace Bookland/Component/MyAccountInformation/Container */
export class MyAccountInformationContainer extends SourceMyAccountInformationContainer {
    containerFunctions = {
        onCustomerSave: this.onCustomerSave.bind(this),
        handleChangeEmailRadioButton: this.handleChangeEmailRadioButton.bind(this),
        handleChangePasswordRadioButton: this.handleChangePasswordRadioButton.bind(this)
    };

    handleChangePasswordRadioButton() {
        this.setState({
            showPasswordChangeField: true,
            showEmailChangeField: false
        });
    }

    handleChangeEmailRadioButton() {
        this.setState({
            showPasswordChangeField: false,
            showEmailChangeField: true
        });
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountInformationContainer));
