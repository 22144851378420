/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import TextPlaceholder from 'Component/TextPlaceholder';
import {
    SearchItem as SourceSearchItem
} from 'SourceComponent/SearchItem/SearchItem.component';

import './SearchItem.override.style';

/** @namespace Bookland/Component/SearchItem/Component */
export class SearchItem extends SourceSearchItem {
    renderContent() {
        const { product: { name } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <span block="SearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </span>
            </figcaption>
        );
    }
}

export default SearchItem;
