/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { OrderType } from 'Type/Order.type';
import { fetchMutation } from 'Util/Request';

import MyAccountOrderInformation from './MyAccountOrderInformation.component';

/** @namespace Bookland/Component/MyAccountOrderInformation/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Bookland/Component/MyAccountOrderInformation/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Bookland/Component/MyAccountOrderInformation/Container */
export class MyAccountOrderInformationContainer extends PureComponent {
    static propTypes = {
        order: OrderType.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    state = {
        isLoading: false,
        retryPaymentTime: 0
    };

    containerFunctions = {
        handleRetryPayment: this.handleRetryPayment.bind(this),
        handleRetryPaymentTime: this.handleRetryPaymentTime.bind(this)
    };

    async handleRetryPayment() {
        const { order: { increment_id, payment_methods }, showNotification } = this.props;
        try {
            this.setState({ isLoading: true });

            const newPaymentUrl = await fetchMutation(OrderQuery.getNewPayUPaymentUrl(increment_id, payment_methods[0]?.type));

            if (newPaymentUrl) {
                window.location.replace(newPaymentUrl?.generateNewPayUPaymentUrlAndSendMail);
            }
        } catch (e) {
            const [error] = e;

            if (error?.message) {
                showNotification('error', error?.message);
            }
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleRetryPaymentTime() {
        const { order: { order_date } } = this.props;

        const actuallyDate = new Date().getTime();
        const orderDate = new Date(order_date).getTime();

        // eslint-disable-next-line no-magic-numbers
        const differenceHours = Number(((actuallyDate - orderDate) / (1000 * 60 * 60)).toFixed(0));

        this.setState({ retryPaymentTime: differenceHours });
    }

    containerProps() {
        const {
            order
        } = this.props;

        const { isLoading, retryPaymentTime } = this.state;

        return {
            order,
            isLoading,
            retryPaymentTime
        };
    }

    render() {
        return (
            <MyAccountOrderInformation
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderInformationContainer);
