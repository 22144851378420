/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Bookland/Util/Browser/Cookie/setCookie */
export function setCookie(name, value, days) {
    let expires = '';

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${ date.toUTCString()}`;
    }
    document.cookie = `${name }=${ value || '' }${expires }; path=/`;
}

/** @namespace Bookland/Util/Browser/Cookie/getCookie */
export function getCookie(name) {
    const cookieList = document.cookie.split('; ') ?? [];

    return cookieList.map((cookie) => {
        const [name, value] = cookie.split('=');

        return {
            name,
            value
        };
    }).find(({ name: cookieName }) => (cookieName === name));
}

/** @namespace Bookland/Util/Browser/Cookie/deleteCookie */
export function deleteCookie(name) {
    const date = new Date();

    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
}
