/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import CSS from 'Util/CSS';

import './ExpandableContent.override.style';

/** @namespace Bookland/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        expandable: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        expandable: false
    };

    scrollToExpandedContent() {
        const { device: { isMobile } } = this.props;
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if ((isContentExpanded && !elem) || !isMobile) {
            return;
        }

        const promoBar = document.querySelector('.PromoCounter_isOpen');

        if (promoBar) {
            const promoOffset = 154;
            CSS.setVariable(this.expandableContentRef, 'content-scroll-offset-y', `${promoOffset}px`);
        }

        elem.scrollIntoView({ behavior: 'smooth' });
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow, device: { isMobile }, expandable, activeOnMobileAndDesktop
        } = this.props;

        if ((!isMobile && !activeOnMobileAndDesktop) && (!isMobile && !expandable)) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <ChevronUpIcon />;
        }

        return <ChevronDownIcon />;
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading, mix, activeOnMobileAndDesktop, arrowIconBeforeHeading
        } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded, activeOnMobileAndDesktop } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                { arrowIconBeforeHeading && this.renderButtonIcon() }
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
                { !arrowIconBeforeHeading && this.renderButtonIcon() }
            </div>
        );
    }

    renderContent() {
        const { children, mix, expandable } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, expandable };

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }
}

export default ExpandableContent;
