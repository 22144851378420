/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import RecaptchaQuery from 'Query/Recaptcha.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

/** @namespace Bookland/Util/Recaptcha/handleVerifyRecaptcha */
export const handleVerifyRecaptcha = async (googleReCaptchaProps) => {
    const store = getStore();
    const { dispatch } = store;
    const { executeRecaptcha } = googleReCaptchaProps || {};

    if (!executeRecaptcha) {
        console.log('Recaptcha has not been loaded');

        return true;
    }

    const token = await executeRecaptcha();
    const data = await fetchMutation(
        RecaptchaQuery.getCheckCaptchaMutation(token)
    );

    if (data) {
        const { checkCaptcha: { success, errors } = {} } = data || {};

        if (success) {
            return true;
        }

        errors?.forEach((error) => {
            dispatch(showNotification('Error', error));
        });

        return false;
    }
};
