/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchOverlayContainer as SourceSearchOverlayContainer
} from 'SourceComponent/SearchOverlay/SearchOverlay.container';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace Bookland/Component/SearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    categories: state.SearchBarReducer.categories
});

/** @namespace Bookland/Component/SearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Bookland/Component/SearchOverlay/Container */
export class SearchOverlayContainer extends SourceSearchOverlayContainer {
    containerProps() {
        const {
            clearSearchResults,
            isHideOverlay,
            isLoading,
            searchCriteria,
            searchResults,
            categories
        } = this.props;

        return {
            clearSearchResults,
            isHideOverlay,
            isLoading,
            searchCriteria,
            searchResults,
            categories
        };
    }
}

export default withReducers({
    SearchBarReducer
})(connect(mapStateToProps, mapDispatchToProps)(SearchOverlayContainer));
