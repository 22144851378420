/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Notification from 'Component/Notification';
import { NotificationList as SourceNotificationList } from 'SourceComponent/NotificationList/NotificationList.component';

import './NotificationList.override.style';

/**
 * Notification List
 * @class NotificationList
 * @namespace Bookland/Component/NotificationList/Component */
export class NotificationList extends SourceNotificationList {
    render() {
        const { onHideNotification, notifications, topNavigationState } = this.props;

        const name = topNavigationState?.navigationState?.name;

        return (
            <div block="NotificationList" mods={ { name } }>
                { Object.keys(notifications).map((id) => (
                    <Notification
                      key={ id }
                      notificationId={ id }
                      notification={ notifications[id] }
                      onHideNotification={ onHideNotification }
                    />
                )) }
            </div>
        );
    }
}

export default NotificationList;
