/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import Header from 'Component/Header';
import MobileSearchField from 'Component/MobileSearchField';
import UrlRewrites from 'Route/UrlRewrites';
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import {
    ADDRESS_BOOK, MY_DOWNLOADABLE, MY_ORDERS, MY_WISHLIST, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account.type';

import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    ASK_ABOUT_POPUP,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECK_ORDER,
    CHECKOUT,
    CMS_PAGE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    FOOTER,
    FORM_MONDLY_POPUP,
    HEADER, HOME,
    LOGIN,
    MENU,
    MOBILE_SEARCH_FIELD,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NOTIFICATION_LIST,
    PRINT_ORDER,
    SEARCH,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from './Router.config';

export const PRINT_ORDER_REQUEST = 'print_order';
export const PRINT_ALL_INVOICES = 'print_all_invoices';
export const PRINT_ALL_SHIPMENT = 'print_all_shipment';
export const PRINT_ALL_REFUNDS = 'print_all_refunds';
export const PRINT_INVOICE = 'print_invoice';
export const PRINT_SHIPMENT = 'print_shipment';
export const PRINT_REFUND = 'print_refund';

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const CookiePopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cookie" */ 'Component/CookiePopup'));
export const AskAboutProductPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ask-about" */ 'Component/AskAboutProductPopup'));
export const DemoNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/DemoNotice'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "homepage" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "password-change" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ 'Route/SearchPage'));
export const SendConfirmationPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "confirmation-page" */ 'Route/SendConfirmationPage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "confirm-account" */ 'Route/ConfirmAccountPage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "menu-page" */ 'Route/MenuPage'));
export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));
export const FormMondlyPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "mondly-form" */ 'Component/FormMondlyPopup'));
export const NavigationTabs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/NavigationTabs'));
export const NotificationList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "wishlist" */ 'Route/WishlistSharedPage'));
export const OfflineNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "offline-notice" */ 'Component/OfflineNotice'));
export const ContactPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/ContactPage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "create-account" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "login-account" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "forgot-password" */ 'Route/ForgotPassword'));
export const SomethingWentWrong = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "something-went-wrong" */ 'Route/SomethingWentWrong'));
export const Breadcrumbs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "breadcrumbs" */ 'Component/Breadcrumbs'));
export const OrderPrintPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "print-order" */ 'Route/OrderPrintPage'));
export const MyBooks = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "my-books" */ 'Route/MyBooks'));
export const CheckOrderPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "check-order-page" */ 'Route/CheckOrderPage'));

/** @namespace Bookland/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Bookland/Component/Router/Component */
export class Router extends SourceRouter {
  [HEADER] = [
      {
          component: <Header />,
          position: 20,
          name: Header
      }
  ];

 [BEFORE_ITEMS_TYPE] = [
     {
         component: <AskAboutProductPopup />,
         position: 10,
         name: ASK_ABOUT_POPUP
     },
     {
         component: <NotificationList />,
         position: 10,
         name: NOTIFICATION_LIST
     },
     {
         component: <DemoNotice />,
         position: 15,
         name: DEMO_NOTICE
     },
     {
         component: <Header />,
         position: 20,
         name: HEADER
     },
     {
         component: <NavigationTabs />,
         position: 25,
         name: NAVIGATION_TABS
     },
     {
         component: <MobileSearchField />,
         position: 25,
         name: MOBILE_SEARCH_FIELD
     },
     {
         component: <Breadcrumbs />,
         position: 30,
         name: BREADCRUMBS
     },
     {
         component: <FormMondlyPopup />,
         position: 31,
         name: FORM_MONDLY_POPUP
     }
 ];

  [SWITCH_ITEMS_TYPE] = [
      {
          component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
          position: 10,
          name: HOME
      },
      {
          component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
          position: 25,
          name: SEARCH
      },
      {
          component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
          position: 40,
          name: CMS_PAGE
      },
      {
          component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
          position: 50,
          name: CART
      },
      {
          component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
          position: 55,
          name: CHECKOUT
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
          position: 60,
          name: CHANGE_PASSWORD
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
          position: 61,
          name: CREATE_ACCOUNT
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
          position: 62,
          name: LOGIN
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
          position: 63,
          name: ACCOUNT_FORGOT_PASSWORD
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ (props) => <SendConfirmationPage { ...props } /> } />,
          position: 64,
          name: CONFIRM_ACCOUNT
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
          position: 65,
          name: CONFIRM_ACCOUNT
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/view/order_id/:orderId?') }
            render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> }
          />,
          position: 70,
          name: MY_ACCOUNT_ORDER
      },
      {
          component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
          position: 71,
          name: MY_ACCOUNT_ORDERS
      },
      {
          component: <Route
            path={ withStoreRegex('/downloadable/customer/products') }
            render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> }
          />,
          position: 72,
          name: MY_ACCOUNT_DOWNLOADABLE
      },
      {
          component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
          position: 73,
          name: MY_ACCOUNT_WISHLIST
      },
      {
          component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
          position: 74,
          name: MY_ACCOUNT_ADDRESS
      },
      {
          component: <Route
            path={ withStoreRegex('/newsletter/manage') }
            render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> }
          />,
          position: 75,
          name: MY_ACCOUNT_NEWSLETTER
      },
      {
          component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
          position: 76,
          name: MY_ACCOUNT
      },
      {
          component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
          position: 80,
          name: MENU
      },
      {
          component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
          position: 81,
          name: SHARED_WISHLIST
      },
      {
          component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
          position: 82,
          name: CONTACT_PAGE
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/print/order_id/:orderId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ORDER_REQUEST } /> }
          />,
          position: 90,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_INVOICES } /> }
          />,
          position: 91,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_SHIPMENT } /> }
          />,
          position: 92,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_REFUNDS } /> }
          />,
          position: 93,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> }
          />,
          position: 94,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_SHIPMENT } /> }
          />,
          position: 95,
          name: PRINT_ORDER
      },
      {
          component: <Route
            path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') }
            render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_REFUND } /> }
          />,
          position: 95,
          name: PRINT_ORDER
      },
      {
          component: <Route path={ withStoreRegex('/moje-podreczniki') } render={ (props) => <MyBooks { ...props } /> } />,
          position: 96,
          name: 'myBooks'
      },
      {
          component: <Route path={ withStoreRegex('/checkOrder') } render={ (props) => <CheckOrderPage { ...props } /> } />,
          position: 100,
          name: CHECK_ORDER
      },
      {
          component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
          position: 1000,
          name: URL_REWRITES
      }
  ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP
        }
    ];

    renderFallbackPage() {
        return null;
    }
}

export default Router;
