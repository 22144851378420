/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { handleVerifyRecaptcha } from 'Util/Recaptcha';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export const BILLING_URL = '/checkout/billing';
export const SHIPPING_URL = '/checkout/shipping';
export { mapDispatchToProps, mapStateToProps };

/** @namespace Bookland/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout
        } = this.props;

        const {
            isSignIn
        } = this.state;
        const isRecaptchaValid = await handleVerifyRecaptcha(this.props.googleReCaptchaProps);

        if (!isRecaptchaValid) {
            return false;
        }
        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs);
                onSignIn();
            } catch (error) {
                showNotification('error', getErrorMessage(error));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        }

        setLoadingState(false);

        if (is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(BILLING_URL) });
        } else if (!is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
        }
    }
}

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer));
