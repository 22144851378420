/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';

import { MyAccountCustomerForm as SourceMyAccountCustomerForm } from 'SourceComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';

import { customerInformationFields } from './MyAccountCustomerForm.form';

/** @namespace Bookland/Component/MyAccountCustomerForm/Component */
export class MyAccountCustomerForm extends SourceMyAccountCustomerForm {
    static propTypes = {
        ...super.propTypes,
        handleChangeEmailRadioButton: PropTypes.func.isRequired,
        handleChangePasswordRadioButton: PropTypes.func.isRequired
    };

    getLegendString() {
        const { showEmailChangeField, showPasswordChangeField } = this.props;

        if (showEmailChangeField && !showPasswordChangeField) {
            return __('email name');
        }

        if (!showEmailChangeField && showPasswordChangeField) {
            return __('password');
        }

        return '';
    }

    get customerInformationFieldMap() {
        const {
            showTaxVatNumber,
            handleChangeEmailRadioButton,
            handleChangePasswordRadioButton,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired,
            customer: {
                firstname = '',
                lastname = '',
                taxvat = '',
                email = ''
            }
        } = this.props;

        return customerInformationFields({
            showTaxVatNumber,
            firstname,
            lastname,
            taxvat,
            email,
            handleChangePasswordRadioButton,
            handleChangeEmailRadioButton,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired
        });
    }
}

export default MyAccountCustomerForm;
