/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

import Accordion from './Accordion.component';

/** @namespace Bookland/Component/Accordion/Container */
export class AccordionContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        className: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string
    };

    static defaultProps = {
        className: '',
        id: '',
        title: ''
    };

    containerFunctions = {
        getAccordionItemHeading: this.getAccordionItemHeading.bind(this),
        getAccordionItemContent: this.getAccordionItemContent.bind(this)
    };

    containerProps() {
        const { className, id, title } = this.props;

        const accordionItems = this.prepareAccordionItems();

        return {
            additionalClassName: className,
            accordionItems,
            id,
            title
        };
    }

    getAccordionItemHeading(item) {
        return item.props.children.filter((child) => child.props.className === 'Accordion-Header')[0];
    }

    getAccordionItemContent(item) {
        return item.props.children.filter((child) => child.props.className === 'Accordion-Content')[0];
    }

    prepareAccordionItems() {
        const { children } = this.props;

        return children.filter((child) => child.props.className === 'Accordion-Box');
    }

    render() {
        return (
            <Accordion
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AccordionContainer;
