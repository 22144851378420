/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as CartIcon } from 'assets/icons/shopping-cart.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { ReactComponent as HideLayoutIcon } from 'src/assets/icons/hide-layout.svg';

import ClickOutside from 'Component/ClickOutside';
import CmsBlock from 'Component/CmsBlock';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import OfflineNotice from 'Component/OfflineNotice';
import PopupSuspense from 'Component/PopupSuspense';
import SearchField from 'Component/SearchField';
import {
    CartOverlay,
    Header as SourceHeader,
    MyAccountOverlay
} from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import { isCrawler, isSSR } from 'Util/Browser';
import { decodeString, noopFn } from 'Util/Common';
import CSS from 'Util/CSS';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { formatPrice } from 'Util/Price';

import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_ORDER,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    NO_MATCH,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH
} from './Header.config';

import './Header.override.style';

export {
    CartOverlay,
    MyAccountOverlay
};

export const CUSTOMER_ACCOUNT_OVERLAY_KEY = 'customer_account';

/** @namespace Bookland/Component/Header/Component */
export class Header extends SourceHeader {
    static propTypes = {
        prices: PropTypes.object.isRequired,
        onTopBarButtonClick: PropTypes.func,
        isOpen: PropTypes.bool.isRequired
    };

    static defaultProps = {
        onTopBarButtonClick: noopFn
    };

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            logo: true,
            title: true,
            search: true
        },
        [NO_MATCH]: {
            title: true
        },
        [POPUP]: {
            title: true,
            close: true
        },
        [PDP]: {
            back: true,
            logo: true
        },
        [CATEGORY]: {
            back: true,
            logo: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true,
            back: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true,
            back: true
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: true,
            back: true
        },
        [CUSTOMER_ORDER]: {
            title: true,
            back: true
        },
        [MENU]: {
            logo: false,
            search: false
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true,
            search: true
        },
        [SEARCH]: {
            logo: true,
            search: true
        },
        [CART]: {
            title: true
        },
        [CART_OVERLAY]: {
            title: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true
        },
        [FILTER]: {
            close: true,
            title: true
        },
        [CHECKOUT]: {
            back: true,
            title: true,
            account: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true
        },
        [CMS_PAGE]: {
            back: true,
            title: true
        },
        [CONTACT_US]: {
            title: true,
            back: true
        },
        [PRODUCT_COMPARE]: {
            title: true,
            back: true
        }
    };

    componentDidUpdate() {
        this.setPromoCounterHeight();
    }

    promoCounterRef = createRef();

    renderPromoCounterWrapper() {
        const {
            isOpen,
            onTopBarButtonClick
        } = this.props;

        return (
            <div block="PromoCounter" mods={ { isOpen } }>
                <CmsBlock identifier="promo_counter" fowardRef={ this.promoCounterRef } />
                <div
                  block="PromoCounter"
                  elem="CloseButton"
                  onClick={ onTopBarButtonClick }
                >
                    &#10005;
                </div>
            </div>
        );
    }

    renderAccountOverlayFallback() {
        return (
            <PopupSuspense
              actualOverlayKey={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
            />
        );
    }

    renderAccountButton() {
        const {
            onMyAccountButtonClick,
            device,
            isCheckout,
            firstname = ''
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        const loggedUserMsg = isCheckout ? __('Welcome, %s!', firstname) : __('My account');

        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              mods={ { isCheckout, isLoggedIn: isSignedIn() } }
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
            >
                <UserIcon />
                { isSignedIn() ? loggedUserMsg : __('Sign In') }
            </button>
        );
    }

    setPromoCounterHeight() {
        const { isOpen } = this.props;
        const store = document.querySelector(':root');
        const promoCounter = document.querySelector('.PromoCounter');

        if (isOpen && !!promoCounter) {
            store.style.setProperty('--promo-bar-mobile-height', `${promoCounter?.clientHeight}px`);
            store.style.setProperty('--promo-bar-desktop-height', '40px');
        } else {
            store.style.setProperty('--promo-bar-mobile-height', '0px');
            store.style.setProperty('--promo-bar-desktop-height', '0px');
        }
    }

    renderAccount(isVisible = false) {
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        // on mobile hide button if not in checkout
        if (isMobile && !isCheckout) {
            return null;
        }

        return (
            <div key="account" block="Header" elem="MyAccountContainer">
                <ClickOutside
                  onClick={ onMyAccountOutsideClick }
                >
                    <div
                      aria-label="My account"
                      block="Header"
                      elem="MyAccount"
                    >
                        { this.renderAccountButton(isVisible) }
                        { this.renderAccountOverlay() }
                    </div>
                </ClickOutside>
            </div>
        );
    }

    renderGrandTotal() {
        const { prices: { grand_total: { value } = {}, quote_currency_code } = {} } = this.props;

        if (!value || value === 0) {
            return null;
        }

        return <span>{ formatPrice(value, quote_currency_code) }</span>;
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
              aria-label={ __('Cart') }
            >
                <CartIcon />
                { this.renderMinicartItemsQty() }
                { this.renderGrandTotal() }
            </button>
        );
    }

    renderTopMenu() {
        const { device: { isMobile }, isCheckout } = this.props;

        if (isMobile) {
            return null;
        }

        const { origin: url } = window.location;

        return (
            <div block="Header" elem="TopMenu">
                <div block="Header" elem="TopMenuWrapper" mods={ { isCheckout } }>
                    <div block="Header" elem="LeftContent">
                        <span block="Header" elem="Contact">
                            <PhoneIcon />
                            <a href="tel:+48459596060">459 596 060</a>
                        </span>
                        <span block="Header" elem="Contact">
                            <MailIcon />
                            <a href="mailto:kontakt@bookland.com.pl">kontakt@bookland.com.pl</a>
                        </span>
                    </div>
                    { !isCheckout && (
                        <div block="Header" elem="RightContent">
                            <Link to={ `${url}/checkOrder` } block="Header" elem="CheckOrderStatus">
                                { __('Order status') }
                            </Link>
                            { this.renderAccount() }
                        </div>
                    ) }
                </div>
            </div>
        );
    }

    renderDesktopIcons() {
        const { isHideLayout } = this.props;

        if (isHideLayout) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="IconsWrapper"
            >
                { this.renderMinicart() }
            </div>
        );
    }

    renderTitle(isVisible = false) {
        const { navigationState: { title, name }, device } = this.props;
        const isCart = name === CART;
        const isPdP = name === PDP;
        const isCategory = name === CATEGORY;
        const isDefault = name === DEFAULT_STATE_NAME;
        const isBlog = window.location.pathname.startsWith('/blog');

        let headerTitle = (isCart) && device.isMobile ? name : title;

        if (isDefault || isPdP || isCategory || isBlog) {
            return null;
        }

        if (!headerTitle && name === POPUP) {
            headerTitle = __('delivery options');
        }

        return (
            <h1
              key="title"
              block="Header"
              elem="Title"
              mods={ { isVisible } }
            >
                { headerTitle ? (<span>{ __(headerTitle) }</span>) : (
                    <span>
                        { headerTitle && decodeString(headerTitle?.replace(/\+/g, ' ')) }
                    </span>
                ) }
            </h1>
        );
    }

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isHideLayout,
            navigationState: { name }
        } = this.props;

        const isDefault = name === DEFAULT_STATE_NAME;

        const isBlog = window.location.pathname.startsWith('/blog');

        // if no src defined from the backend, pass null in order to display placeholder
        // and prevent unnecessary load of corrupted resource
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        if (isHideLayout) {
            return <HideLayoutIcon />;
        }

        const logo = (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );

        if (isDefault && !isBlog) {
            return (
                <h1>
                    { logo }
                </h1>
            );
        }

        return logo;
    }

    renderHideLayout() {
        return (
            <section
              block="Header"
              elem="Wrapper"
              mods={ { isPrerendered: isSSR() || isCrawler(), isHideLayout: true } }
            >
                <header
                  block="Header"
                  ref={ this.logoRef }
                >
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                        <Link to="/" block="Header" elem="GoToStore">
                            { __('Go to store >') }
                        </Link>
                    </nav>
                </header>
                <OfflineNotice />
            </section>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            isHideLayout
        } = this.props;

        if (isCheckout || isHideLayout) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device: { isMobile },
            isHideLayout,
            isPromoCounterVisible
        } = this.props;

        if (isMobile && name === MENU) {
            return null;
        }

        if (!isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        if (isHideLayout) {
            return this.renderHideLayout();
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <header
                  block="Header"
                  mods={ { name, isHiddenOnMobile, isCheckout } }
                  ref={ this.logoRef }
                >
                    { isPromoCounterVisible && this.renderPromoCounterWrapper() }
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                        { isCheckout && (
                            <div block="Header" elem="RightContent">
                                { this.renderAccount() }
                            </div>
                        ) }
                    </nav>
                    { this.renderMenu() }
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default Header;
