/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';

import MyAccountCustomerForm from 'Component/MyAccountCustomerForm';
import { MyAccountInformation as SourceMyAccountInformation } from 'SourceComponent/MyAccountInformation/MyAccountInformation.component';

import './MyAccountInformation.override.style.scss';
/** @namespace Bookland/Component/MyAccountInformation/Component */
export class MyAccountInformation extends SourceMyAccountInformation {
    static propTypes = {
        ...super.propTypes,
        handleChangeEmailRadioButton: PropTypes.func.isRequired,
        handleChangePasswordRadioButton: PropTypes.func.isRequired
    };

    renderCustomerForm() {
        const {
            customer,
            onCustomerSave,
            showEmailChangeField,
            showPasswordChangeField,
            handleChangeEmailRadioButton,
            handleChangePasswordRadioButton
        } = this.props;

        return (
            <MyAccountCustomerForm
              customer={ customer }
              onSave={ onCustomerSave }
              showEmailChangeField={ showEmailChangeField }
              showPasswordChangeField={ showPasswordChangeField }
              handleChangeEmailRadioButton={ handleChangeEmailRadioButton }
              handleChangePasswordRadioButton={ handleChangePasswordRadioButton }
            />
        );
    }
}

export default MyAccountInformation;
