/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UserIcon as SourceUserIcon } from 'SourceComponent/UserIcon/UserIcon.component';

import './UserIcon.override.style';

/** @namespace Bookland/Component/UserIcon/Component */
export class UserIcon extends SourceUserIcon {
}

export default UserIcon;
