/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountTabListContainer as SourceContainer
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.container';
import { isSignedIn } from 'Util/Auth';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Bookland/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends SourceContainer {
    handleLogout() {
        const { onSignOut, logout } = this.props;

        if (!isSignedIn()) {
            return;
        }

        logout();
        onSignOut();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
