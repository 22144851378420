/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    CUSTOMER_ACCOUNT,
    CUSTOMER_ORDER
} from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountContainer as SourceContainer
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    MY_ACCOUNT
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode, replace } from 'Util/Url';

import { ACCOUNT_LOGIN_URL, ACCOUNT_URL } from './MyAccount.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Bookland/Route/MyAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    guestOrder: state.OrderReducer.guestOrder
});

/** @namespace Satisfly/Route/MyAccount/Container/mapDispatchToProps */
export { mapDispatchToProps };

/** @namespace Bookland/Route/MyAccount/Container */
export class MyAccountContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        guestOrder: PropTypes.object.isRequired
    };

    static defaultProps = {
        wishlistItems: {},
        selectedTab: null
    };

    static navigateToSelectedTab(props, state = {}) {
        const {
            match: {
                params: {
                    tab: historyActiveTab
                } = {}
            } = {},
            isMobile,
            selectedTab,
            guestOrder
        } = props;
        const { activeTab } = state;

        if (this.tabMap[selectedTab] && (isSignedIn() || (!isSignedIn() && guestOrder?.id))) {
            return { activeTab: selectedTab };
        }

        // redirect to Dashboard, if user visited non-existent or disabled page
        const newActiveTab = this.tabMap[historyActiveTab] && MyAccountContainer.isTabEnabled(props, historyActiveTab)
            ? historyActiveTab
            : MY_ACCOUNT;
        const { url: activeTabUrl } = this.tabMap[newActiveTab];

        if (historyActiveTab !== newActiveTab && activeTab !== MY_ACCOUNT && isSignedIn() && !isMobile) {
            history.push(appendWithStoreCode(`${ACCOUNT_URL}${activeTabUrl}`));
        }

        if (activeTab !== newActiveTab) {
            return { activeTab: newActiveTab };
        }

        return null;
    }

    redirected = false;

    __construct(props) {
        super.__construct(props);

        const {
            updateMeta,
            toggleOverlayByKey,
            guestOrder
        } = this.props;

        this.state = {
            ...MyAccountContainer.navigateToSelectedTab(this.props),
            isEditingActive: false,
            tabName: '',
            stateSubHeading: ''
        };

        if (!isSignedIn() && !guestOrder?.id) {
            toggleOverlayByKey(CUSTOMER_ACCOUNT);
        }

        updateMeta({ title: __('My account') });
        this.updateBreadcrumbs();
        scrollToTop();

        if (!guestOrder?.id) {
            this.redirectIfNotSignedIn();
            this.onSignIn();
        }
    }

    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            wishlistItems: prevWishlistItems,
            IsSignedInFromState: prevIsSignedInFromState,
            headerState: { name: prevName }
        } = prevProps;

        const {
            wishlistItems,
            IsSignedInFromState: currIsSignedInFromState,
            isLocked,
            activeOverlay,
            headerState: { name },
            guestOrder
        } = this.props;

        const { activeTab: prevActiveTab } = prevState;
        const { activeTab } = this.state;

        this.redirectIfNotSignedIn();

        if (isLocked) {
            this.handleLocked();
        }

        if (prevIsSignedInFromState !== currIsSignedInFromState) {
            this.changeMyAccountHeaderState();
        }

        if (prevActiveTab !== activeTab) {
            this.updateBreadcrumbs();
            this.changeMyAccountHeaderState();

            scrollToTop();
        }

        if (name !== prevName && name !== CUSTOMER_ORDER && !activeOverlay) {
            this.changeMyAccountHeaderState();
        }

        if (Object.keys(wishlistItems).length !== Object.keys(prevWishlistItems).length) {
            this.changeMyAccountHeaderState();
        }

        if (!isSignedIn() && !guestOrder?.id) {
            this.changeMyAccountHeaderState();
        }
    }

    containerProps() {
        const { location, match, guestOrder } = this.props;
        const { activeTab, isEditingActive } = this.state;

        return {
            activeTab,
            isEditingActive,
            location,
            match,
            tabName: this.getTabName(),
            subHeading: this.getSubHeading(),
            guestOrder
        };
    }

    // #region EVENT
    redirectIfNotSignedIn() {
        const {
            baseLinkUrl,
            showNotification,
            guestOrder
        } = this.props;

        if (isSignedIn() || (!isSignedIn() && guestOrder?.id)) { // do nothing for signed-in users
            return;
        }

        if (this.handleCheckIfSelectedTab()) { // do redirect if it is customer url
            history.replace({ pathname: ACCOUNT_LOGIN_URL });
        }

        const path = baseLinkUrl
            ? appendWithStoreCode(ACCOUNT_LOGIN_URL)
            : replace(/\/customer\/account\/?.*/i, ACCOUNT_LOGIN_URL);

        history.replace({ pathname: path });

        if (this.redirected) {
            showNotification('info', __('Please, sign in to access this page contents!'));
        }

        this.redirected = true;
    }
    // #endregion

    updateBreadcrumbs() {
        const { updateBreadcrumbs, guestOrder } = this.props;
        const { activeTab } = this.state;
        const { url, tabName, isFullUrl } = MyAccountContainer.tabMap[activeTab];
        const breadcrumbs = [];

        if (guestOrder?.id && !isSignedIn()) {
            updateBreadcrumbs([{ name: __('My Account'), url: '/' }]);

            return;
        }

        if (activeTab !== MY_ACCOUNT) {
            breadcrumbs.push({
                url: isFullUrl ? url : `${ ACCOUNT_URL }${ url }`,
                name: tabName
            });
        }

        breadcrumbs.push({ name: __('My Account'), url: ACCOUNT_URL });

        updateBreadcrumbs(breadcrumbs);
    }
}

export default withRouter(withReducers({
    OrderReducer
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer)));
