/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import {
    SEARCH
} from 'Component/Header/Header.config';
import SearchField from 'Component/SearchField';

import './MobileSearchField.style';

/** @namespace Bookland/Component/MobileSearchField/Component */
export class MobileSearchField extends PureComponent {
    static propTypes = {
        activeOverlay: PropTypes.string.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired
    };

    render() {
        const {
            activeOverlay, hideActiveOverlay, onClearSearchButtonClick, onSearchBarChange, onSearchBarFocus, onSearchOutsideClick, searchCriteria
        } = this.props;

        return (
            <ContentWrapper mix={ { block: 'MobileSearchField' } }>
                <SearchField
                  key="mobile-search"
                  hideActiveOverlay={ hideActiveOverlay }
                  onClearSearchButtonClick={ onClearSearchButtonClick }
                  onSearchBarFocus={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  searchCriteria={ searchCriteria }
                  isActive={ activeOverlay === SEARCH }
                />
            </ContentWrapper>
        );
    }
}

export default MobileSearchField;
