/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Field } from 'Util/Query';

/** @namespace Bookland/Query/Recaptcha/Query */
export class RecaptchaQuery {
    getCheckCaptchaMutation(token, secret) {
        return new Field('checkCaptcha')
            .addArgument('token', 'String!', token)
            .addArgument('secret', 'String!', secret)
            .addFieldList(['success', 'errors']);
    }
}

export default new RecaptchaQuery();
