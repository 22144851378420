/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import Accordion from 'Component/Accordion';
import Image from 'Component/Image';
import ScrollToId from 'Component/ScrollToId';
import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';

export {
    WidgetFactory
};

/** @namespace Bookland/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { attribs: ['data-scroll-to-id'] },
            replace: this.prepareScrollToLink
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.prepareAccordion
        }
    ];

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs['data-prevent-lazy']) {
            return <Image { ...attributes } isPlain preventLazy />;
        }

        if (attribs.src) {
            return <Image { ...attributes } isPlain />;
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    prepareAccordion({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        return (
            <Accordion { ...attributes }>{ domToReact(children, this.parserOptions) }</Accordion>
        );
    }

    prepareScrollToLink({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);
        const id = attributes['data-scroll-to-id'];

        if (!id) {
            return null;
        }

        return (
            <ScrollToId id={ id }>
                { domToReact(children, this.parserOptions) }
            </ScrollToId>
        );
    }
}

export default Html;
