/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderContainer as SourceContainer
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.container';

import { ORDER_ITEMS } from './MyAccountOrder.config';

export { mapDispatchToProps };

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

/** @namespace Bookland/Component/MyAccountOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    guestOrder: state.OrderReducer.guestOrder
});

/** @namespace Bookland/Component/MyAccountOrder/Container */
export class MyAccountOrderContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        guestOrder: PropTypes.bool.isRequired
    };

    __construct(props) {
        super.__construct(props);

        const {
            match: {
                params: { orderId },
                guestOrder
            }
        } = this.props;

        if (orderId && !guestOrder) {
            this.requestOrderDetails();
        }

        this.state = {
            order: guestOrder || {},
            isLoading: !!guestOrder?.id,
            activeTab: ORDER_ITEMS
        };
    }

    containerProps() {
        const { order: stateOrder, isLoading, activeTab } = this.state;
        const {
            display_tax_in_shipping_amount,
            is_allowed_reorder,
            rss_order_subscribe_allow,
            setTabSubheading,
            isMobile,
            guestOrder
        } = this.props;
        const finalOrder = guestOrder?.id ? guestOrder : stateOrder;

        return {
            display_tax_in_shipping_amount,
            isLoading,
            is_allowed_reorder,
            activeTab,
            rss_order_subscribe_allow,
            setTabSubheading,
            isMobile,
            order: {
                ...finalOrder
            }
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderContainer);
