/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Link from 'Component/Link';
import {
    SearchOverlay as SourceSearchOverlay
} from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import {
    AMOUNT_OF_PLACEHOLDERS
} from './SearchOverlay.config';

import './SearchOverlay.override.style';

/** @namespace Bookland/Component/SearchOverlay/Component */
export class SearchOverlay extends SourceSearchOverlay {
    renderCategoryItem(category) {
        const { name, url } = category;

        return (
            <li>
                <Link to={ url }>
                    <div block="SearchOverlay" elem="CategoriesListItem">{ name }</div>
                </Link>
            </li>
        );
    }

    renderSearchResults() {
        const { categories, searchResults, isLoading } = this.props;

        if (!searchResults?.length && !categories?.length && !isLoading && !this.timeout) {
            return this.renderNoResults();
        }

        const resultsToRender = (isLoading || this.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

        return (
            <>
                <ul
                  block="SearchOverlay"
                  elem="ItemsHolder"
                >
                    { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
                </ul>
                { !isLoading && categories.length > 0 && (
                    <>
                        { searchResults.length > 0 && <hr block="SearchOverlay" elem="HorizontalLine" /> }
                        <h5 block="SearchOverlay" elem="CategoriesTitle">{ __('Categories') }</h5>
                        <ul block="SearchOverlay" elem="CategoriesList">{ categories.map((category) => this.renderCategoryItem(category)) }</ul>
                    </>
                ) }
            </>
        );
    }
}

export default SearchOverlay;
