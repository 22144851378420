/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    UPDATE_HIDELAYOUT
} from './HideLayout.action';

/** @namespace Bookland/Store/HideLayout/Reducer/getInitialState */
export const getInitialState = () => ({
    hideLayout: false
});

/** @namespace Bookland/Store/HideLayout/Reducer/HideLayoutReducer */
export const HideLayoutReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_HIDELAYOUT:
        const { hideLayout } = action;

        return { hideLayout };

    default:
        return state;
    }
};

export default HideLayoutReducer;
