/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const PAYU_METHOD_PAYMENT = 'PayU';
export const PAYU_TYPE = 'payu_gateway';
export const BM_TYPE = 'bluepayment';
export const HOUR_IN_ONE_DAY = 24;
export const BANK = 'bank';
