/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';
import { isMobile } from 'Util/Mobile';

import './FieldSelect.override.style';

/** @namespace Bookland/Component/FieldSelect/Component */
export class FieldSelect extends SourceFieldSelect {
    renderSortSelect() {
        const { isSortSelect } = this.props;

        if (!isSortSelect) {
            return null;
        }

        return (
            <div block="FieldSelect" elem="SortSelect">{ isMobile.any() ? __('Sort') : __('Sort by') }</div>
        );
    }
}

export default FieldSelect;
