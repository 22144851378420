/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';

import { STATE_FORGOT_PASSWORD_SUCCESS } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountForgotPasswordContainer as SourceMyAccountForgotPasswordContainer
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.container';
import transformToNameValuePair from 'Util/Form/Transform';
import { handleVerifyRecaptcha } from 'Util/Recaptcha';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Bookland/Component/MyAccountForgotPassword/Container */
export class MyAccountForgotPasswordContainer extends SourceMyAccountForgotPasswordContainer {
    async onForgotPasswordSuccess(form, fields) {
        const {
            forgotPassword, setSignInState, setLoadingState, forgotPasswordEmail, isOverlayVisible
        } = this.props;
        const submittedEmail = form[0].value;
        const isRecaptchaValid = await handleVerifyRecaptcha(this.props.googleReCaptchaProps);

        if (!isRecaptchaValid) {
            return false;
        }
        setLoadingState(true);

        try {
            await forgotPassword(transformToNameValuePair(fields));
            setSignInState(STATE_FORGOT_PASSWORD_SUCCESS);
            forgotPasswordEmail(submittedEmail);

            // if on route /forgotpassword
            if (!isOverlayVisible) {
                this.showSuccessNotification(submittedEmail);
            }
            setLoadingState(false);
        } catch {
            setLoadingState(false);
        }
    }
}

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordContainer));
