/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export const UPDATE_DELIVERY_POINTS = 'UPDATE_DELIVERY_POINTS';
export const UPDATE_SELECTED_DELIVERY_POINTS = 'UPDATE_SELECTED_DELIVERY_POINTS';

export const UPDATE_REVIEW_REMINDER_ACCEPTED = 'UPDATE_REVIEW_REMINDER_ACCEPTED';

export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_CHECKOUT_SHIPPING_PRICE = 'UPDATE_CHECKOUT_SHIPPING_PRICE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';

/** @namespace Bookland/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Bookland/Store/Checkout/Action/updateShippingPrice */
export const updateShippingPrice = (shippingPrice) => ({
    type: UPDATE_CHECKOUT_SHIPPING_PRICE,
    shippingPrice
});

/** @namespace Bookland/Store/Checkout/Action/updateSelectedDeliveryPoints */
export const updateSelectedDeliveryPoints = (selectedLocker) => ({
    type: UPDATE_SELECTED_DELIVERY_POINTS,
    selectedLocker
});

/** @namespace Bookland/Store/Checkout/Action/updateDeliveryPoints */
export const updateDeliveryPoints = (deliveryPoints) => ({
    type: UPDATE_DELIVERY_POINTS,
    deliveryPoints
});

/** @namespace Bookland/Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Bookland/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/** @namespace Bookland/Store/Checkout/Action/toggleReviewReminderAccepted */
export const toggleReviewReminderAccepted = (reviewReminderAccepted) => ({
    type: UPDATE_REVIEW_REMINDER_ACCEPTED,
    reviewReminderAccepted
});
