/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceStore/Config/Config.action';

export const SHOULD_PROMO_COUNTER_VISIBLE = 'SHOULD_PROMO_COUNTER_VISIBLE';

/** @namespace Bookland/Store/Config/Action/shouldPromoCounterVisible */
export const shouldPromoCounterVisible = (isPromoCounterVisible = true) => ({
    type: SHOULD_PROMO_COUNTER_VISIBLE,
    isPromoCounterVisible
});
