/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';
import getStore from 'Util/Store';

/** @namespace Bookland/Component/App/Component */
export class App extends SourceApp {
    contextProviders = [
        this.renderRedux.bind(this),
        this.renderUnStated.bind(this),
        this.renderRecaptcha.bind(this)
    ];

    renderSharedTransition() {
        return null;
    }

    renderRecaptcha(children) {
        const { ConfigReducer: { recaptcha_frontend_type_recaptcha_v3_public_key = '' } = {} } = getStore().getState() || {};

        return (
            <GoogleReCaptchaProvider
              reCaptchaKey={ recaptcha_frontend_type_recaptcha_v3_public_key }
              scriptProps={ { async: true, defer: true, appendTo: 'body' } }
              key="recaptcha"
            >
                { children }
            </GoogleReCaptchaProvider>
        );
    }
}

export default App;
