/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    ANNOUNCEMENTS_STATUS,
    PRESALE_STATUS, TO_ORDER_STATUS
} from 'Component/Product/Product.config';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import {
    getBundleOptions,
    getIndexedAttributes, getIndexedConfigurableOptions, getIndexedCustomOptions,
    getIndexedReviews, getIndexedSingleVariant, getIndexedVariants
} from 'SourceUtil/Product/Product';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import { isMobile } from 'Util/Mobile';
import getStore from 'Util/Store';

import { getCookie } from '../Browser/Cookie';

export * from 'SourceUtil/Product/Product';

export const feedTypes = {
    ceneo: {
        attributeCode: 'ceneo_tax_adv_discount_price',
        cookieName: 'ceneoAdwIds'
    },
    google: {
        attributeCode: 'google_tax_adv_discount_price',
        cookieName: 'googleAdwIds'
    }
};

/** @namespace Bookland/Util/Product/checkAdvertPrice */
export const checkAdvertPrice = (product) => {
    const { attributes, price_range } = product;

    Object.values(feedTypes).forEach(({ attributeCode, cookieName }) => {
        const advertPriceAttribute = attributes.find(({ attribute_code }) => attribute_code === attributeCode);

        const cookie = getCookie(cookieName);

        const cookieIds = cookie?.value?.split(',')?.map((id) => parseInt(id, 10)) ?? [];

        if (cookieIds?.some((id) => product?.id === id)) {
            if (advertPriceAttribute?.attribute_value && cookieIds?.some((id) => id === product.id)) {
                const oldPrice = price_range.minimum_price.regular_price.value;
                const newPrice = parseFloat(advertPriceAttribute.attribute_value);

                if (price_range.minimum_price.discount.percent_off > 0) {
                    const amountOff = oldPrice - newPrice;
                    // eslint-disable-next-line no-magic-numbers
                    const percentOff = (amountOff / oldPrice) * 100;

                    const discount = {
                        amount_off: amountOff,
                        percent_off: percentOff
                    };

                    price_range.minimum_price.discount = discount;
                    price_range.maximum_price.discount = discount;
                }
                price_range.minimum_price.final_price = { ...price_range.minimum_price.final_price, value: newPrice };
                price_range.maximum_price.final_price = { ...price_range.maximum_price.final_price, value: newPrice };
            }
        }
    });

    return price_range;
};

/** @namespace Bookland/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews,
        items = [],
        bundle_options = []
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    const updatedProduct = {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count
        }
    };

    if (bundle_options.length) {
        updatedProduct.items = getBundleOptions(bundle_options, items);
    }

    return updatedProduct;
};

/** @namespace Bookland/Util/Product/showNewReviewPopup */
export const showNewReviewPopup = () => {
    const store = getStore();
    const {
        ConfigReducer: {
            reviews_allow_guest: isGuestEnabled
        } = {},
        ProductReducer: { product }
    } = store.getState();
    const { dispatch } = store;

    let title = __('Your review about');

    // if not logged in and guest reviews are not enabled
    if (!isSignedIn() && !isGuestEnabled) {
        dispatch(showNotification('info', __('You must login or register to review products.')));

        return;
    }

    if (isMobile.any()) {
        title = __('Your review about %s', product?.name ?? '');
    }

    dispatch(showPopup(REVIEW_POPUP_ID, { title }));
};

/** @namespace Bookland/Util/Product/getSpecialProductType */
export const getSpecialProductType = (product) => {
    let specialProductType = null;

    const { attributes } = product;

    if (!attributes) {
        return null;
    }

    const {
        attributes: {
            special_product_types = null
        } = {}
    } = product;

    if (special_product_types) {
        const { attribute_options, attribute_value } = special_product_types;

        specialProductType = attribute_options[attribute_value]?.label || null;
    }

    return specialProductType;
};

/** @namespace Bookland/Util/Product/isPresaleWithoutDateProduct */
export const isPresaleWithoutDateProduct = (product) => {
    let isPreorderDate = false;
    const specialProductType = getSpecialProductType(product);
    const isPresaleStatus = specialProductType === PRESALE_STATUS || false;

    const {
        attributes
    } = product;

    if (attributes?.preorder_date) {
        const { attribute_value } = attributes?.preorder_date;
        isPreorderDate = attribute_value !== null;
    }

    return isPresaleStatus && !isPreorderDate;
};

/** @namespace Bookland/Util/Product/isPresaleProduct */
export const isPresaleProduct = (product) => {
    let isPreorderDate = false;
    const specialProductType = getSpecialProductType(product);
    const isPresaleStatus = specialProductType === PRESALE_STATUS || false;

    const {
        attributes
    } = product;

    if (attributes?.preorder_date) {
        const { attribute_value } = attributes?.preorder_date;

        isPreorderDate = attribute_value !== null;
    }

    return isPresaleStatus && isPreorderDate;
};

/** @namespace Bookland/Util/Product/getProductPreorderDate */
export const getProductPreorderDate = (product) => {
    const specialProductType = getSpecialProductType(product);
    const isPresaleStatus = specialProductType === PRESALE_STATUS || false;

    const {
        attributes
    } = product;

    return isPresaleStatus && attributes?.preorder_date?.attribute_value !== null ? attributes?.preorder_date?.attribute_value?.split(' ')?.[0] : null;
};

/** @namespace Bookland/Util/Product/getGroupedProductStatus */
export const getGroupedProductStatus = (product, componentName = 'ProductCard') => {
    const { availability } = product;

    let label = __('Available');

    if (!availability) {
        label = __('Out of stock');
    }

    return (
        <span
          block={ componentName }
          elem="Stock"
          mods={ { status: !!availability } }
        >
            { label }
        </span>
    );
};

/** @namespace Bookland/Util/Product/showProductStatus */
export const showProductStatus = (componentName, product, inStock) => {
    const {
        only_in_bookstore
    } = product;

    const { ConfigReducer } = getStore().getState();

    const deliveryTime = ConfigReducer?.satisfly_productavailabilitylist_delivery_time_hours ?? '24';

    const availabilityStatus = getSpecialProductType(product) ?? null;
    const isPresale = isPresaleProduct(product);
    const isPresaleWithoutDate = isPresaleWithoutDateProduct(product);
    let label = '';
    let status = false;

    switch (availabilityStatus) {
    case ANNOUNCEMENTS_STATUS:
        label = __('announcements');
        status = 'announcements';
        break;
    case TO_ORDER_STATUS:
        label = __('to order');
        status = 'to_order';
        break;
    default:
        const modifiedLabelName = componentName === 'ProductCard' ? __('Available') : __('Available - shipping in %sh', deliveryTime);

        label = inStock && !isPresaleWithoutDate ? modifiedLabelName : __('Out of stock');
        status = inStock && !isPresaleWithoutDate;
    }

    if (isPresale) {
        label = __('presale');
        status = 'presale';
    }

    return (
        <span
          block={ componentName }
          elem="Stock"
          mods={ { status: status === TO_ORDER_STATUS ? 'to_order' : status, onlyBookstore: inStock && only_in_bookstore } }
        >
                { (inStock && only_in_bookstore) ? __('in selected bookstores') : label }
        </span>
    );
};

/** @namespace Bookland/Util/Product/getIsCustomPrice */
export const getIsCustomPrice = (product) => {
    let isCustomPrice = false;

    Object.values(feedTypes).forEach(({ attributeCode, cookieName }) => {
        const cookieValue = getCookie(cookieName)?.value?.split(',') ?? '';

        if (cookieValue?.includes(`${product.id}`) && product?.attributes[attributeCode]?.attribute_value) {
            isCustomPrice = true;
        }
    });

    return isCustomPrice;
};

/** @namespace Bookland/Util/Product/getIsCustomPriceGrouped */
export const getIsCustomPriceGrouped = (product) => {
    let isCustomPrice = false;

    Object.values(feedTypes).forEach(({ cookieName }) => {
        const cookieValue = getCookie(cookieName)?.value?.split(',') ?? '';

        if (cookieValue?.includes(`${product.id}`)) {
            isCustomPrice = true;
        }
    });

    return isCustomPrice;
};
