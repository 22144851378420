/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import MyAccountOrderTabs from 'Component/MyAccountOrderTabs';
import { MyAccountOrder as SourceMyAccountOrder } from 'SourceComponent/MyAccountOrder/MyAccountOrder.component';

import './MyAccountOrder.override.style.scss';

/** @namespace Bookland/Component/MyAccountOrder/Component */
export class MyAccountOrder extends SourceMyAccountOrder {
    renderActions() {
        const {
            handleChangeActiveTab,
            activeTab
        } = this.props;

        return (
            <div block="MyAccountOrder" elem="Actions">
                <div block="MyAccountOrder" elem="Buttons">
                    <div>
                        { this.renderReorderButton() }
                        { this.renderSubscriptionButton() }
                    </div>
                    { this.renderDownloadOrder() }
                </div>
                { this.renderOrderComments() }
                <MyAccountOrderTabs
                  tabs={ this.shouldTabsRender() }
                  handleChangeActiveTab={ handleChangeActiveTab }
                  activeTab={ activeTab }
                />
            </div>
        );
    }

    renderDownloadOrder() {
        const { order: { invoice_url } } = this.props;

        if (!invoice_url) {
            return null;
        }

        return (
            <a
              block="MyAccountOrder"
              elem="SubscribeToStatus"
              href={ invoice_url }
              target="_blank"
              rel="noreferrer"
            >
                { __('Download Order') }
            </a>
        );
    }
}

export default MyAccountOrder;
