/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace Bookland/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    containerProps() {
        const { wrapperMix } = this.props;

        return {
            ...super.containerProps(),
            wrapperMix
        };
    }
}

export default FieldContainer;
