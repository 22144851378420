/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { NOT_AVAILABLE_STATUS } from 'Component/PickUpFromBookstorePopup/PickUpFromBookstorePopup.config';
import PRODUCT_TYPE, { ANNOUNCEMENTS_STATUS, PRESALE_STATUS, TO_ORDER_STATUS } from 'Component/Product/Product.config';
import { getMaxQuantity } from 'Util/Product/Extract';

export * from 'SourceUtil/Cart/Cart';

/** @namespace Bookland/Util/Cart/isOnlyBookstoreProductsInCart */
export const isOnlyBookstoreProductsInCart = (items) => {
    // eslint-disable-next-line max-len
    const isOnlyBookstoreProductsInCart = items.filter((item) => item?.product?.only_in_bookstore || item?.quantity > item?.product?.warehouse_salable_qty)?.length > 0;

    return isOnlyBookstoreProductsInCart;
};

/** @namespace Bookland/Util/Cart/isPresaleStatusProductWithoutDate */
export const isPresaleStatusProductWithoutDate = (items) => {
    const isPresaleStatusProductWithoutDate = items?.filter((item) => {
        let isPresaleStatus = false;
        let isPreorderDate = false;

        const {
            attributes: {
                special_product_types = null,
                preorder_date = null
            } = {}
        } = item?.product;

        if (special_product_types) {
            const { attribute_options, attribute_value } = special_product_types;

            isPresaleStatus = attribute_options[attribute_value]?.label === PRESALE_STATUS;
        }

        if (preorder_date) {
            const { attribute_value } = preorder_date;

            isPreorderDate = attribute_value !== null;
        }

        return isPresaleStatus && !isPreorderDate;
    });

    return isPresaleStatusProductWithoutDate?.length > 0;
};

/** @namespace Bookland/Util/Cart/getPresaleProductsInCartArr */
export const getPresaleProductsInCartArr = (items) => {
    const filteredPresaleProductInCartArr = items?.filter((item) => {
        let isPresale = false;
        let isPreorderDate = false;

        const {
            attributes: {
                special_product_types = null,
                preorder_date = null
            } = {}
        } = item?.product;

        if (special_product_types) {
            const { attribute_options, attribute_value } = special_product_types;

            isPresale = attribute_options[attribute_value]?.label === PRESALE_STATUS;
        }

        if (preorder_date) {
            const { attribute_value } = preorder_date;

            isPreorderDate = attribute_value !== null;
        }

        return isPresale && isPreorderDate;
    });

    return filteredPresaleProductInCartArr;
};

/** @namespace Bookland/Util/Cart/isPresaleProductInCart */
export const isPresaleProductInCart = (items) => {
    const filteredPresaleProductInCartArr = getPresaleProductsInCartArr(items);

    const isPresaleProductInCart = filteredPresaleProductInCartArr?.length > 0;

    return isPresaleProductInCart;
};

/** @namespace Bookland/Util/Cart/getPreorderDeliveryMessage */
export const getPreorderDeliveryMessage = (items) => {
    const presaleProductsInCartArr = getPresaleProductsInCartArr(items);

    const isPresale = isPresaleProductInCart(items);

    if (!isPresale) {
        return null;
    }

    const parseDate = (dateString) => new Date(dateString.split(' ')[0]);

    const latestPresaleProduct = presaleProductsInCartArr.reduce((latest, current) => {
        const currentDate = parseDate(current?.product?.attributes?.preorder_date?.attribute_value);
        const latestDate = latest ? parseDate(latest?.product?.attributes?.preorder_date?.attribute_value) : new Date(0);
        return currentDate > latestDate ? current : latest;
    }, null);

    return latestPresaleProduct?.product?.attributes?.preorder_date?.attribute_value?.split(' ')?.[0];
};

/** @namespace Bookland/Util/Cart/isAvailableProductInCart */
export const isAvailableProductInCart = (items) => !!items?.find(({ product }) => {
    const {
        attributes: {
            special_product_types = null
        } = {},
        only_in_bookstore
    } = product;

    const isAvailableProduct = !special_product_types
    || special_product_types !== ANNOUNCEMENTS_STATUS
    || special_product_types !== PRESALE_STATUS
    || special_product_types !== TO_ORDER_STATUS;

    return !only_in_bookstore && isAvailableProduct;
});

/** @namespace Bookland/Util/Cart/isNotEnoughtProductQty */
export const isNotEnoughtProductQty = (items) => !!items?.find((item) => {
    const {
        quantity,
        product = {}
    } = item;

    const maxQty = getMaxQuantity(product);

    return maxQty - quantity < 0;
});

/** @namespace Bookland/Util/Cart/isNotEnoughProductForDeliveryMethods */
export const isNotEnoughProductForDeliveryMethods = (items) => !!items?.find((item) => {
    const {
        product: {
            availability_in_sources = {}
        } = {},
        product
    } = item;

    const maxQty = getMaxQuantity(product);

    const productInWarehouse = availability_in_sources.find((item) => item.source_code === 'lublin');

    const salable_qty = productInWarehouse?.salable_qty;

    return salable_qty < maxQty;
});

/** @namespace Bookland/Util/Cart/isVirtualProductInCart */
export const isVirtualProductInCart = (items) => !!items?.find((item) => {
    const {
        product: {
            type_id = ''
        } = {}
    } = item;

    return type_id === PRODUCT_TYPE.virtual;
});

/** @namespace Bookland/Util/Cart/isConflictInStoresAndProducts */
export const isConflictInStoresAndProducts = (items = [], stores = []) => {
    if (items.length > 1 && stores?.length > 0) {
        return !stores.find((store) => store.availability_code !== NOT_AVAILABLE_STATUS);
    }

    return false;
};
